import Styles from '@screens/not-found/style';
import { useContentMostAccessed } from '@screens/hooks/use-content-most-accessed';
import { useEffect } from 'react';
import { BookIcon, ClockIcon, ContentCard, Toast } from '@pebmed/storybook-react';
import { snowplowButtonClick } from '@analytics/snowplow/events';
import { backgrounds } from '@screens/not-found/backgrounds';
import { TOAST_FIXED_WIDTH } from '@shared/constants/toasts';
import { BREAKPOINT } from './constants';

export default function NotFoundScreen() {
  const { loadContentMostAccessed, contentMostAccessed, error } = useContentMostAccessed();

  const handleCardRedirect = (link: string) => {
    snowplowButtonClick('portal_redirect', '/404', link);
    window.open(link, '_blank');
  };

  useEffect(() => {
    loadContentMostAccessed();
  }, []);

  return (
    <Styles.Container data-testid="not-found">
      <Styles.Background>
        {backgrounds.map((background) => (
          <Styles.Image
            key={Math.random()}
            src={background.src}
            left={background.left}
            top={background.top}
            right={background.right}
            bottom={background.bottom}
            width={background.width}
            height={background.height}
            rightMobile={background.rightMobile}
            topMobile={background.topMobile}
            showMobile={background.showMobile}
          />
        ))}
      </Styles.Background>
      <Styles.Content>
        <Styles.ExternalLink as="a" href="/planos">
          <Styles.Button data-testid="back-button">Voltar para o Whitebook</Styles.Button>
        </Styles.ExternalLink>
        <Styles.Title>Desculpe, essa página não está disponível.</Styles.Title>
        <Styles.Subtitle>
          Como seu tempo é muito importante para nós, que tal dar uma olhada nos artigos mais acessados do Portal
          PEBMED?
        </Styles.Subtitle>
        {error ? (
          <Toast error width={TOAST_FIXED_WIDTH}>
            {error}
          </Toast>
        ) : (
          <Styles.WrapperCard data-testid="most-accessed-cards">
            <Styles.HeaderContainer>
              <Styles.ExternalLink as="a" href={process.env.NEXT_PUBLIC_PORTAL_PEBMED}>
                <Styles.HeaderText>Mais Acessados</Styles.HeaderText>
              </Styles.ExternalLink>
              <Styles.SeeMore data-testid="see-more">Ver Mais &gt;&gt;</Styles.SeeMore>
            </Styles.HeaderContainer>

            <Styles.WrapperContentCard>
              {contentMostAccessed.map((data) => (
                <ContentCard
                  key={data.postId}
                  breakpoint={BREAKPOINT}
                  onClick={() => handleCardRedirect(data.postLink)}
                  title={data.postTitle}
                  startText={{ text: data.postData, icon: <ClockIcon /> }}
                  middleText={{ text: `${data.postReadTime} min.`, icon: <BookIcon /> }}
                />
              ))}
            </Styles.WrapperContentCard>
          </Styles.WrapperCard>
        )}
      </Styles.Content>
    </Styles.Container>
  );
}
