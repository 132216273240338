import UseCaseLoadContentMostAccessed from '@use-cases/load-content-most-accessed';
import { loggerWarn } from '@shared/logger';
import { ContentMostAccessedType } from '@remote-data/load-content-most-accessed/types';
import { useState } from 'react';

interface HooksReturn {
  loadContentMostAccessed(): Promise<boolean>;
  contentMostAccessed: ContentMostAccessedType[];
  error: string;
}

export const useContentMostAccessed = (): HooksReturn => {
  const [error, setError] = useState('');
  const [contentMostAccessed, setContentMostAccessed] = useState([]);

  const loadContentMostAccessed = async () => {
    try {
      const response = await UseCaseLoadContentMostAccessed.run();
      const sliceArr = response.slice(0, 3);
      setContentMostAccessed(sliceArr);
      return true;
    } catch (err) {
      loggerWarn('404Screen', 'loadContentMostAccessedData', err);
      setError('Ops, parece que algo deu errado ao carregar os artigos.');
      return false;
    }
  };

  return { loadContentMostAccessed, contentMostAccessed, error };
};
