import { RemoteError } from '@remote-data/errors';
import AxiosHttpClient from '@remote-data/http-client';
import { HttpClient, HttpResponse, HttpStatusCode } from '@remote-data/http-client/types';
import { contentMostAccessedAdapter } from '@remote-data/load-content-most-accessed/adapters';
import { ContentMostAccessedType } from '@remote-data/load-content-most-accessed/types';

const makeApiUrl = (path: string): string => `${process.env.NEXT_PUBLIC_PORTAL_PEBMED}${path}`;

export class RemoteLoadContentMostAccessed {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {}

  async run(): Promise<HttpResponse<ContentMostAccessedType[]>> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });

    if (httpResponse.statusCode === HttpStatusCode.Ok) {
      return contentMostAccessedAdapter(httpResponse);
    }

    throw new RemoteError(httpResponse.statusCode, httpResponse.body.codigo, httpResponse.body.mensagem);
  }
}

export default new RemoteLoadContentMostAccessed(
  makeApiUrl('/wp-json/pebmed_wpapi/v1/top_contents_week'),
  AxiosHttpClient,
);
