import { PageHeaderTypes, PageLayoutTypes } from '@layout/types';
import NotFoundScreen from '@screens/not-found';

export default NotFoundScreen;

export async function getStaticProps() {
  return {
    props: {
      layout: PageLayoutTypes.FreeContent,
      header: PageHeaderTypes.Empty,
    },
  };
}
