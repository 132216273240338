import RemoteLoadContentMostAccessed, {
  RemoteLoadContentMostAccessed as RemoteLoadContentMostAccessedType,
} from '@remote-data/load-content-most-accessed';
import { ContentMostAccessedType } from '@remote-data/load-content-most-accessed/types';
import { handleError } from '@use-cases/errors/error-handling';

const USE_CASE_NAME_LOGGER = 'UseCaseLoadContentMostAccessed';

export class UseCaseLoadContentMostAccessed {
  constructor(private readonly remoteLoadContentMostAccessed: RemoteLoadContentMostAccessedType) {}

  async run(): Promise<ContentMostAccessedType[]> {
    try {
      const response = await this.remoteLoadContentMostAccessed.run();
      return response.body;
    } catch (error) {
      return handleError(error, USE_CASE_NAME_LOGGER, []);
    }
  }
}

export default new UseCaseLoadContentMostAccessed(RemoteLoadContentMostAccessed);
