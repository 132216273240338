export const backgrounds = [
  {
    src: '/images/ico-circle-white.svg',
    left: '-54px',
    top: '-97px',
    showMobile: true,
  },
  {
    src: '/images/ico-hook-white.svg',
    left: '-2px',
    top: '440px',
    showMobile: false,
  },
  {
    src: '/images/ico-hook-orange.svg',
    right: '219px',
    top: '275px',
    height: '61px',
    width: '52px',
    rightMobile: '55px',
    topMobile: '139px',
    showMobile: true,
  },
  {
    src: '/images/ico-hook-white.svg',
    left: '50%',
    top: '-50px',
    height: '195px',
    width: '179px',
    showMobile: false,
  },
  {
    src: '/images/ico-hook-white.svg',
    left: '20%',
    bottom: '-190px',
    height: '-194px',
    width: '320px',
    showMobile: false,
  },
  {
    src: '/images/ico-circle-white.svg',
    right: '-75px',
    top: '109px',
    showMobile: false,
  },
  {
    src: '/images/ico-circle-white.svg',
    right: '-49px',
    bottom: '136px',
    height: '98px',
    width: '98px',
    showMobile: false,
  },
];
