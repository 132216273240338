import { HttpResponse } from '@remote-data/http-client/types';
import { ContentMostAccessedResponse, ContentMostAccessedType } from '@remote-data/load-content-most-accessed/types';

const parseResponseToJSON = (response: string): ContentMostAccessedResponse[] => {
  return JSON.parse(response);
};

export const contentMostAccessedAdapter = ({
  statusCode,
  body,
}: HttpResponse<string>): HttpResponse<ContentMostAccessedType[]> => {
  return {
    statusCode,
    body: parseResponseToJSON(body).map((data) => ({
      img: data.img,
      postData: data.post_data,
      postId: data.post_id,
      postLink: data.post_link,
      postReadTime: data.post_read_time,
      postTitle: data.post_title,
    })),
  };
};
