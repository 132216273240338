import styled, { css } from 'styled-components';
import { BREAKPOINT } from './constants';

interface PositionAttrs {
  right?: string;
  left?: string;
  top?: string;
  bottom?: string;
  rightMobile?: string;
  topMobile?: string;
  showMobile: boolean;
}

const sharedStyle = css`
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  color: ${({ theme }) => theme.tokens.gray[1100]};
  @media only screen and(max-width: ${BREAKPOINT}) {
    font-size: 14px;
  }
`;

const Container = styled.main`
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const Image = styled.img.attrs((props: PositionAttrs) => ({ props }))<PositionAttrs>`
  position: absolute;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};

  :nth-of-type(5) {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  @media only screen and(max-width: ${BREAKPOINT}) {
    display: ${({ showMobile }) => (showMobile ? 'block' : 'none')};
    right: ${({ rightMobile }) => rightMobile};
    top: ${({ topMobile }) => topMobile};
  }
`;

const Content = styled.div`
  position: relative;
  width: 78%;
  max-width: 1230px;
  padding: 50px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media only screen and(max-width: ${BREAKPOINT}) {
    width: 85%;
  }
`;

const Button = styled.span`
  position: absolute;
  right: 0;
  width: fit-content;
  color: ${({ theme }) => theme.tokens.gray[1300]};
  font-size: 14px;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.tokens.gray[1300]};
  border-radius: 18px;
  text-decoration: none;

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.tokens.gray[100]};
    background-color: ${({ theme }) => theme.tokens.gray[1300]};
    transition: color 0.2s, background-color 0.2s;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.tokens.gray[1300]};
  font-weight: 500;
  font-size: 26px;
  margin: 65px 0 30px;
  position: relative;

  &:before {
    content: 'Ops...';
    color: ${({ theme }) => theme.tokens.blue[800]};
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    top: 30px;
    left: -90px;
    background-color: ${({ theme }) => theme.tokens.orange[500]};
    width: 64px;
    height: 12px;
    border-radius: 12px;
    @media only screen and(max-width: ${BREAKPOINT}) {
      left: -75px;
    }
  }
`;

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.tokens.gray[1300]};
  font-size: 22px;
  font-weight: normal;
  max-width: 720px;
  margin: 0;
`;

const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 45px;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  @media only screen and(max-width: ${BREAKPOINT}) {
    width: 100%;
  }
`;

const HeaderText = styled.p`
  ${sharedStyle}
`;

const SeeMore = styled.p`
  ${sharedStyle}
  display: none;

  @media only screen and(max-width: ${BREAKPOINT}) {
    display: block;
  }
`;

const WrapperContentCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  @media only screen and(max-width: ${BREAKPOINT}) {
    overflow: auto;
    width: 100%;
  }

  section {
    margin-right: 30px;
    @media only screen and(max-width: 950px) {
      min-width: 90%;
      margin-right: 10px;
    }
  }
  section:last-of-type {
    margin-right: 0;
  }
`;

const ExternalLink = styled.div`
  text-decoration: none;
`;

export default {
  Container,
  Content,
  Background,
  Image,
  Button,
  Title,
  Subtitle,
  WrapperCard,
  WrapperContentCard,
  HeaderContainer,
  HeaderText,
  SeeMore,
  ExternalLink,
};
